import { Component, Vue } from 'vue-property-decorator';
import { excludeUserTypes, UserRole, UserType } from '@/models/User';
import UserManagement from '@/components/UserManagement/UserManagement.vue';

@Component<Users>({
  components: {
    UserManagement,
  },
})
export default class Users extends Vue {
  protected get userTypes(): UserType[] {
    return this.$store.state.isServiceOrganization ? excludeUserTypes([UserRole.SIMPLE, UserRole.SIMPLE_PLUS, UserRole.HELPDESK_TCMG]) : excludeUserTypes([UserRole.SIMPLE, UserRole.SIMPLE_PLUS]);
  }
}
